import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const AppContext = React.createContext({});
export const NavContext = React.createContext({});
export const ChatContext = React.createContext({});

export const isBrowser = () => typeof window !== `undefined`;

export const NavProvider = ({ children }) => {
  return <NavContext.Provider value={{}}>{children}</NavContext.Provider>;
};

export const ChatProvider = ({ children }) => {
  const [contactOpen, setContactOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);

  useEffect(() => {
    setContactOpen(false);
    setChatOpen(false);
  }, []);

  return (
    <ChatContext.Provider
      value={{ contactOpen, setContactOpen, chatOpen, setChatOpen }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const AppProvider = (props) => {
  const { site } = useStaticQuery(AppContextStaticQuery);
  const [isAppInitializedState, setAppInitializedState] = useState(false);
  const [sanityProjectId, setSanityProjectId] = useState(
    site?.siteMetadata?.sanityProjectId,
  );
  const [sanityDataset, setSanityDataset] = useState(
    site?.siteMetadata?.sanityDataset,
  );

  useEffect(() => {
    setTimeout(() => {
      setAppInitializedState(true);
    }, 2500);
  }, [isAppInitializedState]);

  return (
    <AppContext.Provider
      value={{
        sanityProjectId,
        setSanityProjectId: (sanityProjectId) =>
          setSanityProjectId(sanityProjectId),
        sanityDataset,
        setSanityDataset: (sanityDataset) => setSanityDataset(sanityDataset),
        isAppInitializedState,
        setAppInitializedState: (isAppInitialized) =>
          setAppInitializedState(isAppInitialized),
      }}
    >
      <div id="uber">{props.children}</div>
    </AppContext.Provider>
  );
};

const AppContextStaticQuery = graphql`
  query SiteMetaQueryRe {
    site {
      siteMetadata {
        sanityDataset
        sanityProjectId
        title
        description
      }
    }
  }
`;
