import { AppProvider, NavProvider, ChatProvider } from './src/state/appContext';
import './src/styles/tailwind.css';
import './src/styles/app.css';
import 'animate.css';

// console.log(process.env.GATSBY_IN_DEV);

if (process.env.GATSBY_IN_DEV) {
  if (typeof window !== `undefined`) {
    window.LOG_LEVEL = `DEBUG`;
  }
}

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <NavProvider>
      <ChatProvider>{element}</ChatProvider>
    </NavProvider>
  </AppProvider>
);
